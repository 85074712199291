<template>
  <div id="recommendations" class="p-4">
    <div class="row align-items-center align-item-middle pb-4">
      <div class="col-6">
        <h3 class="mb-0">Recommendations</h3>
      </div>
      <div class="col-6 text-right">
        <a href="javascript:"  class="btn btn-primary btn-sm "
            @click="showSendBrochuresModal = true">
            Send Brochures
        </a>
        <router-link
          :to="{ name: 'KarenV2Listing', params: { 'id': leadEvent.lead_id, 'eventId': leadEvent.id } }"
          class="btn btn-sm btn-primary karen-link"
        >
          KAREN
        </router-link>
        <router-link
          :to="{ name: 'LeadEventRecommendation', params: { 'id': leadEvent.lead_id, 'eventId': leadEvent.id } }"
          class="btn btn-sm btn-primary add-recommendation-link"
        >
          + Add
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <venue-recommendation
          :lead-event="leadEvent"
        ></venue-recommendation>
      </div>
    </div>
    <el-dialog
        title="Send Brochures"
        width="50%"
        :visible.sync="showSendBrochuresModal"
        v-if="showSendBrochuresModal"
        :close-on-click-modal="false"
        :append-to-body="true"
    >
        <SendBrochureRequestEmail :lead-event-id="leadEvent.id" @close="showSendBrochuresModal = false"/>
    </el-dialog>
  </div>
</template>
<script>
import VenueRecommendation from '@/views/Widgets/LeadEventDetails/VenueRecommendation';
import SendBrochureRequestEmail from '@/views/Widgets/BrochureRequestEmail/SendBrochureRequestEmail';
import recommendationMixin from "@/helpers/recommendationMixin";
import { Dialog } from "element-ui";
export default {
  mixins: [recommendationMixin],
  components: {
    VenueRecommendation,
    SendBrochureRequestEmail,
    [Dialog.name]: Dialog,
  },
  props: {
    leadEvent: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      recommendationPortalLoading: false,
      showSendBrochuresModal: false
    };
  },
};
</script>