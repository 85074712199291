<template>
  <div class="lead-email-preview">
    <h6 class="heading-small text-muted mb-4 mt-4">Emails</h6>
    <el-table
      :data="emails"
      row-key="id"
      id="emails_data_table"
      v-loading="loading"
      header-row-class-name="thead-light"
    >
      <el-table-column label="Date" prop="created_at">
        <template v-slot="{ row }">
          <span class="created_at mb-0 text-sm">{{
            row.created_at | moment("DD MMM YYYY hh:mm A")
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Subject" prop="email_subject">
        <template v-slot="{ row }">
          <a href="javascript:" @click="showEmail(row.email_message, row.email_subject)">{{
            row.email_subject
          }}</a>
        </template>
      </el-table-column>

      <el-table-column label="Recipient" prop="email_to">
        <template v-slot="{ row }">
          {{ row.email_to }}
        </template>
      </el-table-column>
      <el-table-column label="Status" prop="email_status">
        <template v-slot="{ row }">
          <span class="text-warning" v-if="row.email_status == 'queued'" >Queued</span>
          <span class="text-success" v-if="row.isEmailOpened === true" >Opened</span>
          <span class="text-success" v-if="row.isEmailOpened !== true && row.email_status == 'sent'" >Sent</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      id="dialog_time_slot_taken"
      :title="email_subject"
      :visible.sync="dialogVisible"
      :show-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="true"
      width="800px"
    >
      <div v-html="email_content"></div>
    </el-dialog>
  </div>
</template>
<script>
import { Table, TableColumn, dialog } from "element-ui";
import Vue from "vue";
import moment from "moment";
Vue.use(dialog);
export default {
  name: "lead-emails",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    leadId: {
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      email_content: "",
      email_subject: "",
      dialogVisible: false,
    };
  },
  computed: {
    emails() {
      let emails = this.$store.getters["leads/getEmailsByLeadId"](this.leadId);

      // Go through each emails and add a new property to check if email is opened
      emails.forEach((email) => {
        email.isEmailOpened = this.isEmailOpened(email);
      });

      return emails;
    },
  },
  async created() {
    await this.$store.dispatch("leads/getEmails", this.leadId);
    this.loading = false;
  },
  methods: {
    showEmail(email_content, email_subject) {
      this.email_content = email_content;
      this.email_subject = email_subject;
      this.dialogVisible = true;
    },
    showDialogclose() {
      this.dialogVisible = false;
    },
    isEmailOpened(email) {
      if (! email.email_sent_at || !email.email_last_open_date) {
        return false;
      }

      // Parse the dates with the appropriate format:
      const emailSentAt = moment(email.email_sent_at, "YYYY-MM-DD HH:mm:ss.SSSSSS");
      const emailLastOpen  = moment(email.email_last_open_date, "YYYY-MM-DD HH:mm:ss.SSSSSS");

      // Calculate the difference in milliseconds
      const diffInMs = emailLastOpen.diff(emailSentAt);

      // Convert milliseconds to seconds then if the period in less than 40 seconds consider not open manually.
      return ((diffInMs / 1000) > 40) ? true : false;
    }
  },
};
</script>

<style>
.lead-email-preview b,
.lead-email-preview strong {
  font-weight: bold !important;
}
</style>
