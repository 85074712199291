<template>
    <div>
        <div v-loading="loading" class="send-brochure-request-email" v-if="! preview">
            <base-input
                type="text"
                label="Email Subject"
                placeholder="Email Subject"
                v-model="emailTemplateSubject"
            >
            </base-input>
            <base-input label="Email Body">
                <html-editor name="editor" :value="emailTemplate" @input="editEmailText" ></html-editor>
            </base-input>
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <base-input label="Venue Name">
                            <el-select v-model="selectedVenue" @change="fetchBrochures" value-key="id" filterable :clearable="true" placeholder="Select Venue" class="w-100">
                                <el-option-group
                                    label="Venues"  v-if="engagedVenues.length">
                                    <el-option
                                        v-for="venue in engagedVenues"
                                        :key="venue.id"
                                        :label="venue.name"
                                        :value="venue">
                                    </el-option>
                                </el-option-group>
                                <el-option-group
                                    label="" v-if="nonEngagedVenues.length">
                                    <el-option
                                        v-for="venue in nonEngagedVenues"
                                        :key="venue.id"
                                        :label="venue.name"
                                        :value="venue">
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </base-input>
                    </div>
                    <div>
                        <h4>Files</h4>
                        <hr style="margin-top: 10px; margin-bottom: 10px"/>
                        <div v-for="(data, index) in selectedVenueBrochures" :key="index">
                            <div class="row">
                                <div class="col-12">
                                    <span class="font-weight-bold">{{data.original_file_name}}</span>
                                    <el-link v-if="! isAttachmentSelected(data.id)" class="el-icon-plus mr-1 mt-1 float-right text-xs text-primary" @click.stop="selectAttachment(data)">Add</el-link> 
                                </div>
                            </div>
                            <hr style="margin-top: 10px; margin-bottom: 10px"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h4>Selected Attachments</h4>
                    <hr style="margin-top: 10px; margin-bottom: 10px"/>
                    <div v-for="(data, index) in attachments" :key="index">
                        <div class="row">
                            <div class="col-12">
                                <span class="font-weight-bold">{{data.name}}</span>
                                <ul>
                                    <li v-for="(attachment, index) in data.attachments" :key="index">
                                        {{attachment.original_file_name}}
                                        <el-link class="el-icon-close ml-1 text-xs float-right text-danger" @click.stop="removeAttachment(data, attachment)"> Remove</el-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer text-right">
                <button class="btn edit" @click="close">
                    Cancel
                </button>
                <button class="btn edit submit" :class="isAttachmentsAdded? `btn-primary` : `btn-secondary` "  id="send_venue_recommendation_final" @click.stop="previewBrochureRequestEmail">
                    Preview
                </button>
            </div>
        </div>
        <div v-loading="loading" class="send-brochure-request-email-preview" v-if="preview">
            <div v-html="previewedContent"></div>
            <div slot="footer" class="dialog-footer text-right">
                <button class="btn edit" @click="preview = false">
                    Back
                </button>
                <button class="btn btn-primary edit submit" id="send_venue_recommendation_final" @click.stop="sendBrochureRequestEmail">
                    Send
                </button>
            </div>

        </div>
    </div>
    
</template>

<script>

import HtmlEditor from "@/components/Inputs/HtmlEditor";
import { Select, Option, OptionGroup, Link, Message } from 'element-ui';
import { LeadEventService } from "@/services";
export default {
    components: {
		[HtmlEditor.name]: HtmlEditor,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Link.name]: Link,
	},
	props:{
		leadEventId: {
			required: true
		},
    },
    data() {
		return {
			emailTemplateText: '',
			emailTemplateSubject: '',
			loading: true,
            selectedVenue: null,
            attachments: {},
            preview: false,
            previewedContent: '',
		};
	},
    computed: {
        emailSubjectMessage() {
			return 'Venue Brochure Request';
		},
        emailTemplate() {
			return this.template();
		},
        leadInfo() {
			return this.$store.getters["leadEvent/getLeadEventsById"](this.leadEventId);
		},
        engagedVenues() {
            const allVenues = [...this.$store.state.venues.items];
            const recommendations = this.$store.getters["recommendations/getRecommendationsByLeadEventId"](this.leadEventId) || [];
            const enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEventId) || [];
            const recommendedVenueIds = recommendations.map(rec => rec.id);
            const enquiredVenueIds = enquiries.map(enq => enq.venue_id);
            return allVenues
                .filter(venue => recommendedVenueIds.includes(venue.id) || enquiredVenueIds.includes(venue.id))
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        nonEngagedVenues() {
            const allVenues = [...this.$store.state.venues.items];
            const recommendations = this.$store.getters["recommendations/getRecommendationsByLeadEventId"](this.leadEventId) || [];
            const enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEventId) || [];
            const recommendedVenueIds = recommendations.map(rec => rec.id);
            const enquiredVenueIds = enquiries.map(enq => enq.venue_id);
            return allVenues
                .filter(venue => !recommendedVenueIds.includes(venue.id) && !enquiredVenueIds.includes(venue.id))
                .sort((a, b) => a.name.localeCompare(b.name));
        },
        selectedVenueBrochures() {
            if (! this.selectedVenue) {
                return [];
            }

            return this.$store.getters["venues/getVenueBrochures"](this.selectedVenue.id);
        },
        isAttachmentsAdded() {
            return Object.values(this.attachments).length > 0;
        }
    },
    async mounted() {
        this.emailTemplateSubject = this.emailSubjectMessage;
		await this.$store.dispatch("leadEvent/getSingle", this.leadEventId);
        await this.$store.dispatch("venues/getAll", this.country);
        await this.$store.dispatch("recommendations/getRecommendations", this.leadEventId);
        await this.$store.dispatch("leadEvent/getEnquiries", this.leadEventId);
        this.$nextTick(() => {
            this.emailTemplateText = this.template();
        });
		this.loading = false;
	},
    methods: {
		template() {
			return this.line(`Hi, ` + this.leadInfo.first_name) +
                this.line(`Great to be in touch!`) +
                this.line(`Delighted to provide some further info on the gorgeous - {{VENUE_NAMES}}`) +
                this.line(`Thank you for your enquiry! Please see attached for further info on packages and pricing.`, true) +
                this.line(`{{VENUE_LINKS}}`) +
                this.line(`<strong>Did you have a date in mind for your wedding? </strong>`) + 
                this.line(`Let me know and I can check availability and minimum spends for you.`, true) +
                this.line(`I would love to connect you to the venue to <strong>set up a visit</strong>. - I would recommend doing this ASAP as dates book up very quickly!`, true) + 
                this.line(`<strong>Is a weekday or weekend preferred?</strong>`, true) +
                this.line(`I will also send over some further venues which suit your guest numbers, style and budget in a separate email. Please check your junk as it may slip into there!`, true) +
                this.line(`<strong><i>As far as how our service works, it's 100% free to you and we quote the exact same as the venues - we just ask that you allow us to introduce you to any of the venues we recommend.</i></strong>`, true) +
                this.line(`In the meantime I welcome any questions or queries and will look forward to hearing from you!`, true) +
                this.line(`Warm regards,`, true) +
                this.line(`{{SIGNATURE}}`, true);
		},
		line(text, extraLine) {
			extraLine = extraLine || false;
			return `
				<p style="font-family: 'Nunito', Arial, Sans-serif; font-size: 15px; letter-spacing: 0.4px; word-break: break-word; font-weight: 200;">
					${text}
				</p> `
				+ ((extraLine) ? '<p></p>' : '');
		},
        editEmailText(text) {
			this.emailTemplateText = text;
		},
        fetchBrochures() {
            this.$nextTick(() => {
                if (this.selectedVenue) {
                    this.$store.dispatch("venues/getVenueBrochures", this.selectedVenue.id);
                }
            });
        },
        selectAttachment(data) {
            let attachments = this.attachments[this.selectedVenue.id] || {id: this.selectedVenue.id, name: this.selectedVenue.name, attachments: []};
            attachments.attachments.push(data);

            this.$set(this.attachments, this.selectedVenue.id, attachments);
        },
        isAttachmentSelected(attachmentId) {
            if (! this.selectedVenue) {
                return false;
            }

            let attachments = this.attachments[this.selectedVenue.id];

            if (! attachments) {
                return false;
            }

            return attachments.attachments.find(attachment => attachment.id === attachmentId);
        },
        removeAttachment(data, attachment) {
            let attachments = this.attachments[data.id];

            if (! attachments) {
                return;
            }

            attachments.attachments = attachments.attachments.filter(att => att.id !== attachment.id);

            this.$set(this.attachments, data.id, attachments);

            if (attachments.attachments.length === 0) {
                this.$delete(this.attachments, data.id);
            }
        },
        close() {
            this.$emit('close');
        },
        async sendBrochureRequestEmail() {
            this.loading = true;
            let response = await LeadEventService.sendBrochureRequestEmail(this.leadEventId, {
                email_subject: this.emailTemplateSubject,
                email_body: this.convertStrongWithStyleParam(this.emailTemplateText),
                attachments: this.attachments,
            });
            this.loading = false;
            if (response?.data?.success) {
                Message.success({
                    message: 'Email sent successfully',
                    type: 'success'
                });
            } else {
                Message.error({
                    message: 'Failed to send email',
                    type: 'error'
                });
            }
            this.$emit('close');
        },
        previewBrochureRequestEmail() {
            if (! this.isAttachmentsAdded) {
                return;
            }
            this.previewedContent = this.renderSelectedVenues();
            this.preview = true;
        },
        renderSelectedVenues() {
            let template = this.emailTemplateText;
            // Convert attachments object to array for processing
            let attachmentsArray = Object.values(this.attachments);

            // Create HTML table
            let attachmentTemplate = '<table>';

            // Get comma-separated venue names
            let venueNames = attachmentsArray.map(venue => venue.name).join(', ');

            // Build table rows for each venue and its attachments
            attachmentsArray.forEach(venue => {
                attachmentTemplate += `<tr><td>${venue.name.toUpperCase()}</td></tr>`;
                attachmentTemplate += `<tr><td><ul>`;
                venue.attachments.forEach(attachment => {
                    attachmentTemplate += `<li><a style="color:#da6b35" href="${attachment.public_url}">${attachment.original_file_name}</a></li>`;
                });

                attachmentTemplate += '</ul></td></tr>';
            });

            attachmentTemplate += '</table>';

            // Replace placeholders in template
            template = template.replace('{{VENUE_LINKS}}', attachmentTemplate);
            template = template.replace('{{VENUE_NAMES}}', venueNames);
            template = template.replace('{{SIGNATURE}}', '');

            return template;
        },
        convertStrongWithStyleParam(template) {
            return template.replace(/<strong>/g, '<strong style="font-weight: bold !important;">');
        }
    }
}
</script>
<style>
.send-brochure-request-email .ql-editor {
    max-height: 300px;
    overflow-y: auto;
}

.send-brochure-request-email-preview b,
.send-brochure-request-email-preview strong {
    font-weight: bold !important;
}

.send-brochure-request-email .el-link--inner{
    font-size: 14px;
    font-family: sans-serif;
}
</style>