<template>
    <el-form
        class="mark-tentative-form"
        :model="formData"
        ref="mark-tentative-form"
        v-loading="loading"
    >
    <div class="form-content">
        <div class="row">
            <div class="col-md-6">
                <base-input label="Venue Name">
                    <el-select v-model="selectedVenue" value-key="venue_id" :clearable="true" placeholder="Select Venue" class="w-100">
                        <el-option-group
                            label="Venues"  v-if="venues.length">
                            <el-option
                                v-for="venue in venues"
                                :key="venue.id"
                                :label="venue.name"
                                :value="venue"
                                @click="">
                            </el-option>
                        </el-option-group>
                        <el-option-group
                            label="Custom Venues" v-if="customVenuesEngaged.length">
                            <el-option
                                v-for="venue in customVenuesEngaged"
                                :key="venue.id"
                                :label="venue.name"
                                :value="venue">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input label="Event Type">
                    <el-select  placeholder="Event Type" v-model="formData.event_type" disabled>
                        <el-option
                            v-for="option in eventTypeOptions"
                            :key="option.label"
                            :label="option.label"
                            :value="option.value"
                        >
                        </el-option>
                    </el-select>
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <base-input label="Name">
                    <el-input v-model="formData.name" placeholder="Name"></el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input :label="this.formData.event_type == `corporate` ? 'Company' : `Partner's Name`">
                    <el-input v-if="this.formData.event_type == `corporate`" v-model="formData.company_name" placeholder="Company's Name"></el-input>
                    <el-input v-else v-model="formData.partner_name" placeholder="Partner's Name"></el-input>
                </base-input>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <base-input label="Event Name">
                    <el-input v-model="formData.event_name" placeholder="Wedding"></el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input label="PAX">
                    <el-input v-model="formData.guest_count" placeholder="#" @keydown.native="allowNumericOnly"></el-input>
                </base-input>
            </div>
        </div>
        <div class="row justify-content-start">
            <div class="col-md-6 ">
                <base-input label="Event Date">
                    <el-date-picker
                    v-model="formData.event_date"
                    type="date"
                    placeholder="Pick a Date"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    class="w-100"
                    />
                </base-input>
            </div>
            <div class="col-md-6 ">
                <base-input label="Tentative Close Date">
                    <el-date-picker
                    v-model="formData.close_date"
                    type="date"
                    placeholder="Pick a Date"
                    :picker-options="closeDatePickerOptions"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                    class="w-100"
                    />
                </base-input>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <base-input label="Sale Value">
                    <el-input 
                        v-model="formData.sale_value" 
                        placeholder="Please input"
                        @keydown.native="allowNumericOnly">
                        <template slot="prepend">{{currency}}</template>
                    </el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-6">
                        <base-input label=" Comm. Rate % ">
                            <el-input 
                                :disabled="! formData.commission_overridden"
                                v-model="formData.commission_rate" 
                                placeholder="10.0"
                                @keydown.native="allowNumericOnly">
                            </el-input>
                        </base-input>
                    </div>
                    <div class="col-md-6 text-right">
                        <base-input label=" Override ">
                            <el-switch
                                v-model="formData.commission_overridden"
                            >
                            </el-switch>
                        </base-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <base-input label="Calculated Commission">
                    <el-input v-model="calculatedCommission">
                        <template slot="prepend">{{currency}}</template>
                    </el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input>
                    <template slot="label">
                        <label class="form-control-label"> Is CTL? </label>
                        <label class="float-right">
                            <el-switch
                                v-model="formData.is_ctl"
                                active-text=""
                            >
                            </el-switch>
                        </label>
                    </template>
                    <el-select :disabled="! formData.is_ctl" v-model="formData.ctl_rep_id" placeholder="Select Rep" class="w-100">
                        <el-option
                            v-for="venueSpecialist in venueSpecialists"
                            :key="venueSpecialist.id"
                            :label="venueSpecialist.name"
                            :value="venueSpecialist.id">
                        </el-option>
                    </el-select>
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <base-input :label="formData.commission_overridden ? `Commission Notes (Required)` : `Commission Notes (optional)`">
                    <el-input
                        ref="commission_notes"
                        type="textarea"
                        v-model="formData.commission_notes"
                        :rows="3"
                        :class="formData.commission_overridden && !formData.commission_notes ? `color-red`: ``"
                    ></el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input label="Internal Notes">
                    <el-input
                        type="textarea"
                        v-model="formData.internal_notes"
                        :rows="3"
                    ></el-input>
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <base-input>
                    <template slot="label">
                        <label class="form-control-label"> Payment Terms </label>
                        <label class="float-right">
                            <el-switch
                                v-model="formData.payment_comments_enabled"
                            >
                            </el-switch>
                        </label>
                    </template>
                
                    <el-input
                        type="textarea"
                        :disabled="! formData.payment_comments_enabled"
                        v-model="formData.payment_comments"
                        placeholder="STA - Standard Terms Agreed"
                        :rows="3"
                    ></el-input>
                </base-input>
            </div>
            <div class="col-md-6">
                <base-input label="Likelihood To Close">
                    <el-radio-group v-model="formData.likelihood_to_close" size="medium">
                        <el-radio label="LOW">
                            <span class="red-dot">
                                <i class="fa-solid fa-check" v-if="formData.likelihood_to_close == `LOW`"></i>
                                <i v-else>&nbsp;</i>
                            </span>
                        </el-radio>
                        <el-radio label="MEDIUM">
                            <span class="yellow-dot">
                                <i class="fa-solid fa-check" v-if="formData.likelihood_to_close == `MEDIUM`"></i>
                                <i v-else>&nbsp;</i>
                            </span>
                        </el-radio>
                        <el-radio label="HIGH">
                            <span class="green-dot">
                                <i class="fa-solid fa-check" v-if="formData.likelihood_to_close == `HIGH`"></i>
                                <i v-else>&nbsp;</i>
                            </span>
                        </el-radio>
                    </el-radio-group>
                </base-input>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <div class="text-right">
                    <base-button type="secondary" @click="cancel">
                        Cancel
                    </base-button>
                    <base-button 
                        type="primary"
                        class="ml-2"
                        :disabled="!isFormValid"
                        @click="save">
                            Save
                    </base-button>
                </div>
            </div>
        </div>
    </div>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { 
  Select, 
  Option, 
  OptionGroup,
  Input, 
  DatePicker,
  Switch,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Message
} from 'element-ui';

export default {
    name: 'MarkAsTentative',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Input.name]: Input,
        [DatePicker.name]: DatePicker,
        [Switch.name]: Switch,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
    },
    props: {
        leadEvent: {
            type: Object,
            required: true,
        },
        tentativeSale: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            loading: false,
            formData: {
                currency: '', // enum('AUD','GBP','USD')
                locatable_id: '',
                event_name: '',
                partner_name: '',
                company_name: '',
                event_type: this.leadEvent.event_type,
                name: '',
                guest_count: '',
                event_date: '',
                close_date: moment().add(14, 'days').format('YYYY-MM-DD'),
                sale_value: '',
                commission_rate: '10.0',
                commission_overridden: false,
                is_ctl: false,
                ctl_rep_id: '',
                ctl_rep_name: '',
                commission_notes: '',
                internal_notes: '',
                likelihood_to_close: '',
                payment_comments_enabled: false,
                payment_comments: 'STA - Standard Terms Agreed',
                is_custom_venue	: false,
                is_update: false,
            },
            selectedVenue: '',
            eventTypeOptions: [
                {label: "Wedding", value: "wedding"},
                {label: "Corporate", value: "corporate"},
                {label: "Social", value: "social"},
            ],
            closeDatePickerOptions: {
                disabledDate(time) {
                    // Disable all dates prior today
                    return time.getTime() + 24 * 60 * 60 * 1000  < Date.now();
                },
            },
        };
    },
    computed: {
        ...mapState("country/", {
            country: (state) => state.country || 'AU',
        }),
        lead() {
            return this.$store.getters["leads/getLeadById"](this.leadEvent.lead_id);
        },
        lastEnquiry() {
            let enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEvent.id);
            return enquiries ? (enquiries[enquiries.length - 1]) : {};
        },
        currency() {
            return this.country.toLowerCase() === 'uk' ? '£' : '$';
        },
        customVenues() {
            return this.$store.getters["customVenues/getVenues"](this.country);
        },
        calculatedCommission() {
            if (!this.formData.sale_value || !this.formData.commission_rate) return '';
            const commission = (parseFloat(this.formData.sale_value) * parseFloat(this.formData.commission_rate) / 100).toFixed(2);
            this.formData.total_commission = commission;
            return commission;
        },
        isFormValid() {
            const paymentComments = this.formData.payment_comments_enabled ?
                 (this.formData.payment_comments ? true : false) : true;

            const commissionComments = this.formData.commission_overridden ?
                 (this.formData.commission_notes ? true : false) : true;

            const ctl = this.formData.is_ctl ? 
                (this.formData.ctl_rep_id ? true : false) : true;
            
            return this.formData.locatable_id &&
                this.formData.name &&
                this.formData.event_name &&
                this.formData.event_date &&
                this.formData.sale_value &&
                this.formData.commission_rate &&
                this.formData.likelihood_to_close &&
                paymentComments &&
                ctl &&
                commissionComments;
        },
        venues() {
            const allVenues = [...this.$store.state.venues.items];
            const recommendations = this.$store.getters["recommendations/getRecommendationsByLeadEventId"](this.leadEvent.id) || [];
            const enquiries = this.$store.getters["leadEvent/getEnquiriesByLeadEventId"](this.leadEvent.id) || [];
            const recommendedVenueIds = recommendations.map(rec => rec.id);
            const enquiredVenueIds = enquiries.map(enq => enq.venue_id);
            return allVenues.map(venue => ({
                    ...venue,
                    venue_id: 'system-' + venue.id,
                    isCustomVenue: false,
                }))
                .filter(venue => recommendedVenueIds.includes(venue.id) || enquiredVenueIds.includes(venue.id));
        },
        customVenuesEngaged() {
            return this.customVenues
                .map(venue => ({
                    ...venue,
                    venue_id: 'custom-' + venue.id,
                    isCustomVenue: true,
                }));
        },
        venueSpecialists() {
            let venueSpecialists = this.$store.state.venues.venueSpecialists;
            let data = [];
            for (let vc in venueSpecialists) {
                vc = venueSpecialists[vc];
                if(vc.hubspot_owner_id && vc.country.toUpperCase() === this.country) {
                    data.push({
                        name: vc.name,
                        id: vc.id
                    });
                }
            }
            return data;
        },
    },
    async created() {
        this.loading = true;
        await this.$store.dispatch("venues/getAll", this.country);
        await this.$store.dispatch("recommendations/getRecommendations", this.leadEvent.id);
        await this.$store.dispatch("leadEvent/getEnquiries", this.leadEvent.id);
        await this.$store.dispatch("venues/getVenueSpecialists");
        await this.$store.dispatch("customVenues/getAll", this.country);
        this.loading = false;
        this.loadDefaultFormValues();
    },
    methods: {
        allowNumericOnly(event) {
            const specialKeys = [
                'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape', '.',
            ];

            if (specialKeys.includes(event.key)) {
                return;
            }

            let char = String.fromCharCode(event.keyCode);
            const pattern = /[0-9]/;

            if (!pattern.test(char)) {
                event.preventDefault();
            }
        },
        cancel() {
            this.$emit('cancel');
        },
        async save() {
            if (!this.isFormValid) return;
            
            this.loading = true;
            let response = await this.$store.dispatch(
                'tentativeSales/saveTentativeSale', 
                {
                    leadEventId: this.leadEvent.id,
                    data: this.formData
                }
            );

            response = response?.response?.data;
            if (response?.errors) {
                for (let error in response.errors) {
                    Message.error(response.errors[error]);
                }
                this.loading = false;
                return;
            }

            this.loading = false;
            this.$emit('submit');
        },
        getDefaultValues() {
            if (this.tentativeSale?.id) {
                return this.tentativeSale;
            }
            return {
                currency: this.country.toLowerCase() === 'au' ? 'AUD' : (this.country.toLowerCase() === 'uk' ? 'GBP' : ''),
                guest_count: this.leadEvent.estimated_guests,
                event_date: moment(this.leadEvent.event_start).format('YYYY-MM-DD'),
                estimated_guests: this.leadEvent.estimated_guests,
                sale_value: this.leadEvent.estimated_budget,
                event_name: this.leadEvent.name,
                name: this.lead.first_name,
                company_name: this.lead.company,
                partner_name: this.lastEnquiry.partner_first_name,
                commission_rate: 10.0, // TODO:Need to populate this data from selected venue.
                is_ctl: false,
                commission_overridden: false
            }
        },
        loadDefaultFormValues() {
            const defaultValues = this.getDefaultValues();
            this.formData.currency = defaultValues.currency;
            this.formData.name = defaultValues.name;
            this.formData.partner_name = defaultValues.partner_name;
            this.formData.locatable_id = (this.tentativeSale.id) ? this.tentativeSale.locatable_id : '';
            if (this.tentativeSale.id && this.tentativeSale.locatable_type == 'App\\Models\\CustomVenue') {
                this.formData.is_custom_venue = true;
            }
            if (this.formData.locatable_id) {
                this.selectDefaultVenue();
            }
            this.formData.event_name = defaultValues.event_name;
            this.formData.company_name = defaultValues.company_name;
            this.formData.name = defaultValues.name;
            this.formData.guest_count = defaultValues.guest_count;
            this.formData.event_date = defaultValues.event_date;
            if (defaultValues.close_date) {
                this.formData.close_date = defaultValues.close_date;
            }
            this.formData.sale_value = defaultValues.sale_value;
            this.formData.commission_rate = defaultValues.commission_rate;
            this.formData.commission_overridden = defaultValues.commission_overridden;
            this.formData.is_ctl = defaultValues.is_ctl;
            this.formData.ctl_rep_id = defaultValues.ctl_rep_id;
            this.formData.commission_notes = defaultValues.commission_notes;
            this.formData.internal_notes = defaultValues.internal_notes;
            this.formData.likelihood_to_close = defaultValues.likelihood_to_close;
            this.formData.payment_comments_enabled = defaultValues.payment_comments ? true : false;
            this.formData.payment_comments = defaultValues.payment_comments;
            if (this.tentativeSale?.id) {
                this.formData.is_update = true;
            }
        },
        selectDefaultVenue() {
            if (this.formData.locatable_id && this.formData.is_custom_venue) {
                let venue = this.customVenuesEngaged.filter(venue => this.formData.locatable_id == venue.id).shift();
                this.selectedVenue = venue
            } else if (this.formData.locatable_id) {
                let venue = this.venues.filter(venue => this.formData.locatable_id == venue.id).shift();
                this.selectedVenue = venue
            }
        }
    },
    watch: {
        'formData.commission_overridden': function() {
            if (this.formData.commission_overridden) {
                this.$refs.commission_notes.focus();
            }
        },
        'selectedVenue': function() {
            if (this.selectedVenue) {
                this.formData.locatable_id = this.selectedVenue.id;
                this.formData.is_custom_venue = this.selectedVenue.isCustomVenue ? true : false;
            }
        }
    }
};
</script>

<style>
.mark-tentative-form .form-content {
  padding: 20px;
}
.mark-tentative-form .w-100 {
  width: 100%;
}
.mark-tentative-form .gap-3 {
  gap: 1rem;
}
.mark-tentative-form .red-dot,
.mark-tentative-form .yellow-dot,
.mark-tentative-form .green-dot {
    margin-left: -25px;
    width: 25px;
    height: 25px;
    display: inline-block;
    border-radius: 25px;
    z-index: 100;
    position: relative;
    margin-bottom: -10px;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    border:1px;
    color: #000;
}
.mark-tentative-form .red-dot {
    background-color: #F56C6C;
    border-color: #AE0000;
}
.mark-tentative-form .yellow-dot {
    background-color: #E6A23C;
    border-color: #A86500;
}
.mark-tentative-form .green-dot {
    background-color: #67C23A;
    border-color: #2F8E00;
}
.color-red textarea{
    border: 1px solid #f00 !important;
}
</style>
